@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  font-family: "GalanoGrotesque-Bold";
  src: url("../assets/fonts/GalanoGrotesque-Bold.eot");
  src: url("../assets/fonts/GalanoGrotesque-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/GalanoGrotesque-Bold.woff2") format("woff2"),
    url("../assets/fonts/GalanoGrotesque-Bold.woff") format("woff"),
    url("../assets/fonts/GalanoGrotesque-Bold.ttf") format("truetype"),
    url("../assets/fonts/GalanoGrotesque-Bold.svg#GalanoGrotesque-Bold") format("svg");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GalanoGrotesque-Medium";
  src: url("../assets/fonts/GalanoGrotesque-Medium.eot");
  src: url("../assets/fonts/GalanoGrotesque-Medium.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/GalanoGrotesque-Medium.woff2") format("woff2"),
    url("../assets/fonts/GalanoGrotesque-Medium.woff") format("woff"),
    url("../assets/fonts/GalanoGrotesque-Medium.ttf") format("truetype"),
    url("../assets/fonts/GalanoGrotesque-Medium.svg#GalanoGrotesque-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GalanoGrotesque-SemiBold";
  src: url("../assets/fonts/GalanoGrotesque-SemiBold.eot");
  src: url("../assets/fonts/GalanoGrotesque-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/GalanoGrotesque-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/GalanoGrotesque-SemiBold.woff") format("woff"),
    url("../assets/fonts/GalanoGrotesque-SemiBold.ttf") format("truetype"),
    url("../assets/fonts/GalanoGrotesque-SemiBold.svg#GalanoGrotesque-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Galano Grotesque";
  src: url("../assets/fonts/GalanoGrotesque-Regular.eot");
  src: url("../assets/fonts/GalanoGrotesque-Regular.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/GalanoGrotesque-Regular.woff2") format("woff2"),
    url("../assets/fonts/GalanoGrotesque-Regular.woff") format("woff"),
    url("../assets/fonts/GalanoGrotesque-Regular.ttf") format("truetype"),
    url("../assets/fonts/GalanoGrotesque-Regular.svg#GalanoGrotesque-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilmer";
  src: url("../assets/fonts/Gilmer-Medium.eot");
  src: url("../assets/fonts/Gilmer-Medium.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Gilmer-Medium.woff2") format("woff2"),
    url("../assets/fonts/Gilmer-Medium.woff") format("woff"),
    url("../assets/fonts/Gilmer-Medium.ttf") format("truetype"),
    url("../assets/fonts/Gilmer-Medium.svg#GilmerMedium") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: url("../assets/fonts/BR-Omny-Medium.eot");
  src: url("../assets/fonts/BR-Omny-Medium.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/BR-Omny-Medium.woff2") format("woff2"),
    url("../assets/fonts/BR-Omny-Medium.woff") format("woff"),
    url("../assets/fonts/BR-Omny-Medium.ttf") format("truetype"),
    url("../assets/fonts/BR-Omny-Medium.svg#BROmny") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.project-tree ::-webkit-scrollbar {
  width: 12.5px;
  height: 10px;
}
.project-tree ::-webkit-scrollbar-thumb {
  background: var(--dashboard-hr);
  border-radius: 10px;
  border: 3px solid var(--dashboard-background);
}
.project-tree ::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  box-shadow: inset 5px 10px 12px var(--gray);

  border: 3px solid var(--dashboard-background);
}

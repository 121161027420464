@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.project-tree ::-webkit-scrollbar {
  width: 12.5px;
  height: 10px;
}
.project-tree ::-webkit-scrollbar-thumb {
  background: var(--dashboard-hr);
  border-radius: 10px;
  border: 3px solid var(--dashboard-background);
}
.project-tree ::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  box-shadow: inset 5px 10px 12px var(--gray);

  border: 3px solid var(--dashboard-background);
}

:root {
  --transparent: transparent;
  --white: #ffffff;
  --black: #000000;
  --gray: #cccccc;
  --light-gray: #efefef;
  --dark-gray: #cfd9e6;

  --primary: #1d4f90;
  --secondary: #3fbfad;
  --third: #65cbc9;
  --background-default: #efefef;
  --highlighted: #ef7622;
  --dark-blue: #000033; /* app color */

  --footer-background: #cfd9e6;
  --footer-copyright: #efefef;

  --input-background: #1d5251a8;
  --input-border: #3fbfad;
  --input-text: #3fbfad;
  --input-border-focus: #ffffff;
  --input-text-focus: #ffffff;
  --input-text-focus-bg: #1d52515e;
  --input-label-focus: #82fffd;
  --input-text-highlighted: #8ff2e4;
  --input-autocomplete-bg: #1d5251;
  --input-autocomplete-text: #ffffff;
  --input-secondary-background: #efefef;
  --input-secondary-border: #707070;
  --input-secondary-text: #707070;
  --input-secondary-border-focus: #1d4f90;
  --input-secondary-text-focus: #1d4f90;
  --input-tertiary-border-focus: #1d4f90af;
  --input-tertiary-text-focus: #1d4f90af;

  --icon-background: #707070;
  --icon-text: #cfd9e6;
  --icon-focus: #1d4f90;

  --form-sign-background: #3fbfad61;
  --form-sign-border: #3fbfad66;

  --flag-background: #e0e0e0;
  --flag-shadow: #0000001f;

  --flip-background: transparent;
  --flip-front-background: transparent;
  --flip-front-text: #ffffff;
  --flip-back-background: #1d5251;
  --flip-back-text: #ffffff;

  --tag-color: #3fbfad;
  --tag-text-color: #222222;

  --text-default: #222222;
  --text-default-light: #707070;
  --text-primary: #1d4f90;

  --overlay-90: #000000de;
  --overlay-85: #00000085;
  --overlay-84: #000000ad;
  --overlay-65: #000000a6;
  --overlay-30: #00000061;
  --overlay-20: #00000033;
  --box-shadow: #00000029;

  --table-border: rgba(224, 224, 224, 1);

  --toast-error: #fae1e2;
  --toast-success: #f0f9fa;

  /* Dashboard */
  --dashboard-background: #efefef;
  --dashboard-primary-bg: #1d4f90;
  --dashboard-primary-bg-light: #3f51b5;
  --dashboard-secondary: #ef7622;
  --dashboard-menu-bg: #163d68;
  --dashboard-text-default: #222222;
  --dashboard-light-text: #f5f5f5;
  --dashboard-light-border: #f5f5f5;
  --dashboard-highlighted: #65cbc9;
  --dashboard-success: #3fbf7e;
  --dashboard-btn-success: #4caf50;
  --dashboard-btn-success-dark: #009a00;
  --dashboard-hr: #484848;
  --dashboard-top-toolbar: #222222;
  --dashboard-separator-title: #747474;
  --dashboard-border-light: #ffffff;
  --dashboard-box-shadow: #b1b1b1;
  --dashboard-icon-info: #4c5c71;
  --dashboard-separator-line: #c2c7e4;
  --dashboard-separator-tag: #c2c7e4;
  --dashboard-table-header: #ebebeb;
  --dashboard-danger: #ef2222;

  --card-background: #f6f6f6;
  --card-shadow: #00000029;
  --card-border: #1d4f90;
}

@font-face {
  font-family: "GalanoGrotesque-Bold";
  src: url(/static/media/GalanoGrotesque-Bold.c828586b.eot);
  src: url(/static/media/GalanoGrotesque-Bold.c828586b.eot?#iefix) format("embedded-opentype"),
    url(/static/media/GalanoGrotesque-Bold.cb2cb4e3.woff2) format("woff2"),
    url(/static/media/GalanoGrotesque-Bold.c5d965ff.woff) format("woff"),
    url(/static/media/GalanoGrotesque-Bold.8a3a54d1.ttf) format("truetype"),
    url(/static/media/GalanoGrotesque-Bold.1c309ca4.svg#GalanoGrotesque-Bold) format("svg");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GalanoGrotesque-Medium";
  src: url(/static/media/GalanoGrotesque-Medium.98617c08.eot);
  src: url(/static/media/GalanoGrotesque-Medium.98617c08.eot?#iefix) format("embedded-opentype"),
    url(/static/media/GalanoGrotesque-Medium.6fa011b7.woff2) format("woff2"),
    url(/static/media/GalanoGrotesque-Medium.6741d600.woff) format("woff"),
    url(/static/media/GalanoGrotesque-Medium.2210857a.ttf) format("truetype"),
    url(/static/media/GalanoGrotesque-Medium.a237ae37.svg#GalanoGrotesque-Medium) format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GalanoGrotesque-SemiBold";
  src: url(/static/media/GalanoGrotesque-SemiBold.ceb680c5.eot);
  src: url(/static/media/GalanoGrotesque-SemiBold.ceb680c5.eot?#iefix) format("embedded-opentype"),
    url(/static/media/GalanoGrotesque-SemiBold.158c1b4d.woff2) format("woff2"),
    url(/static/media/GalanoGrotesque-SemiBold.dc315770.woff) format("woff"),
    url(/static/media/GalanoGrotesque-SemiBold.5fdf4c29.ttf) format("truetype"),
    url(/static/media/GalanoGrotesque-SemiBold.ebf33378.svg#GalanoGrotesque-SemiBold) format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Galano Grotesque";
  src: url(/static/media/GalanoGrotesque-Regular.1bdfa119.eot);
  src: url(/static/media/GalanoGrotesque-Regular.1bdfa119.eot?#iefix) format("embedded-opentype"),
    url(/static/media/GalanoGrotesque-Regular.9dd84b40.woff2) format("woff2"),
    url(/static/media/GalanoGrotesque-Regular.e66eb93a.woff) format("woff"),
    url(/static/media/GalanoGrotesque-Regular.57835de7.ttf) format("truetype"),
    url(/static/media/GalanoGrotesque-Regular.399996b2.svg#GalanoGrotesque-Regular) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilmer";
  src: url(/static/media/Gilmer-Medium.3262d854.eot);
  src: url(/static/media/Gilmer-Medium.3262d854.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Gilmer-Medium.bc75dc8c.woff2) format("woff2"),
    url(/static/media/Gilmer-Medium.0c7a9db4.woff) format("woff"),
    url(/static/media/Gilmer-Medium.3090b8fc.ttf) format("truetype"),
    url(/static/media/Gilmer-Medium.fc54518b.svg#GilmerMedium) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BROmny";
  src: url(/static/media/BR-Omny-Medium.ba06b6bf.eot);
  src: url(/static/media/BR-Omny-Medium.ba06b6bf.eot?#iefix) format("embedded-opentype"),
    url(/static/media/BR-Omny-Medium.e9e4e0d1.woff2) format("woff2"),
    url(/static/media/BR-Omny-Medium.e0ce66c2.woff) format("woff"),
    url(/static/media/BR-Omny-Medium.4fc2b73f.ttf) format("truetype"),
    url(/static/media/BR-Omny-Medium.ee6da30e.svg#BROmny) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  outline: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Galano Grotesque", sans-serif;
}
html {
  overflow-x: hidden;
  height: 100%;
}
img {
  max-width: 100%;
}
body,
html {
  font-family: "Galano Grotesque", sans-serif;
  height: 100%;
  width: 100vw;
  background: var(--background-default);
  font-size: 16px;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}
ul,
ol {
  padding: 0;
}

/* Tools */
.txt-highlighted {
  color: var(--dashboard-highlighted);
}
.txt-light {
  color: var(--dashboard-separator-title);
}
.txt-success {
  color: var(--dashboard-success);
}
.txt-danger {
  color: var(--dashboard-title);
}
.tools-menu li {
  font-size: 14px;
  padding: 0;
}
.tools-menu li label {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 3px 13px;
}
.tools-menu li label em {
  padding-bottom: 4px;
  font-style: normal;
}
.tools-menu li .material-icons {
  font-size: 18px;
  color: var(--dashboard-icon-info);
  margin-right: 5px;
}

/* Geral Autocomplete Inputs */
/* input:-webkit-autofill {
  box-shadow: 0 0 30px 30px var(--input-autocomplete-bg) inset !important;
  -webkit-box-shadow: 0 0 30px 30px var(--input-autocomplete-bg) inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-autocomplete-text) !important;
} */

input:-internal-autofill-selected {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* Rounded Default Buttons */
button {
  border-radius: 50px;
  border: 2px solid transparent;
  margin: 0 5px;
  outline: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: "Galano Grotesque", sans-serif !important;
}

button.MuiButton-containedPrimary {
  color: var(--white);
  border-color: var(--dashboard-secondary);
  background: var(--dashboard-secondary);
  border-radius: 50px !important;
}

.MuiOutlinedInput-root {
  border-radius: 25px;
}

.MuiPaper-root {
  background: var(--card-background);
}

.MuiButton-label .material-icons {
  margin-right: 3px;
}

/* Effects */
@-webkit-keyframes droneAnimation {
  from {
    background-size: 130%;
  }

  to {
    background-size: 160%;
  }
}
@keyframes droneAnimation {
  from {
    background-size: 130%;
  }

  to {
    background-size: 160%;
  }
}

@-webkit-keyframes pressMenu {
  0% {
    opacity: 0;
  }
}

@keyframes pressMenu {
  0% {
    opacity: 0;
  }
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Print */
@media print {
  @page {
    /* size: A4 landscape; //use this line if you want landscape printing - map page 4mm margin*/
    size: A4;
    margin: 0;
    background: #ffffff;
  }

  html,
  body {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: #ffffff;
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
  }

  header,
  footer {
    display: none;
  }

  .page {
    margin: 0;
    border: medium none currentColor;
    border: initial;
    border-radius: 0;
    border-radius: initial;
    width: auto;
    width: initial;
    min-height: 0;
    min-height: initial;
    box-shadow: none;
    box-shadow: initial;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
    page-break-after: always;
  }
}

/* Special Medias */
@media screen and (max-width: 680px) {
  .MuiMenu-list li {
    min-height: 24px;
  }
}

#google_map img[role="presentation"] {
  width: 257px !important;
  height: 257px !important;
}


@import "./colors.css";
@import "./fonts.css";

* {
  outline: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Galano Grotesque", sans-serif;
}
html {
  overflow-x: hidden;
  height: 100%;
}
img {
  max-width: 100%;
}
body,
html {
  font-family: "Galano Grotesque", sans-serif;
  height: 100%;
  width: 100vw;
  background: var(--background-default);
  font-size: 16px;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}
ul,
ol {
  padding: 0;
}

/* Tools */
.txt-highlighted {
  color: var(--dashboard-highlighted);
}
.txt-light {
  color: var(--dashboard-separator-title);
}
.txt-success {
  color: var(--dashboard-success);
}
.txt-danger {
  color: var(--dashboard-title);
}
.tools-menu li {
  font-size: 14px;
  padding: 0;
}
.tools-menu li label {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 3px 13px;
}
.tools-menu li label em {
  padding-bottom: 4px;
  font-style: normal;
}
.tools-menu li .material-icons {
  font-size: 18px;
  color: var(--dashboard-icon-info);
  margin-right: 5px;
}

/* Geral Autocomplete Inputs */
/* input:-webkit-autofill {
  box-shadow: 0 0 30px 30px var(--input-autocomplete-bg) inset !important;
  -webkit-box-shadow: 0 0 30px 30px var(--input-autocomplete-bg) inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-autocomplete-text) !important;
} */

input:-internal-autofill-selected {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* Rounded Default Buttons */
button {
  border-radius: 50px;
  border: 2px solid transparent;
  margin: 0 5px;
  outline: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: "Galano Grotesque", sans-serif !important;
}

button.MuiButton-containedPrimary {
  color: var(--white);
  border-color: var(--dashboard-secondary);
  background: var(--dashboard-secondary);
  border-radius: 50px !important;
}

.MuiOutlinedInput-root {
  border-radius: 25px;
}

.MuiPaper-root {
  background: var(--card-background);
}

.MuiButton-label .material-icons {
  margin-right: 3px;
}

/* Effects */
@keyframes droneAnimation {
  from {
    background-size: 130%;
  }

  to {
    background-size: 160%;
  }
}

@keyframes pressMenu {
  0% {
    opacity: 0;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Print */
@media print {
  @page {
    /* size: A4 landscape; //use this line if you want landscape printing - map page 4mm margin*/
    size: A4;
    margin: 0;
    background: #ffffff;
  }

  html,
  body {
    width: 100%;
    height: fit-content;
    background: #ffffff;
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
  }

  header,
  footer {
    display: none;
  }

  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

/* Special Medias */
@media screen and (max-width: 680px) {
  .MuiMenu-list li {
    min-height: 24px;
  }
}

#google_map img[role="presentation"] {
  width: 257px !important;
  height: 257px !important;
}

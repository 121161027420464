:root {
  --transparent: transparent;
  --white: #ffffff;
  --black: #000000;
  --gray: #cccccc;
  --light-gray: #efefef;
  --dark-gray: #cfd9e6;

  --primary: #1d4f90;
  --secondary: #3fbfad;
  --third: #65cbc9;
  --background-default: #efefef;
  --highlighted: #ef7622;
  --dark-blue: #000033; /* app color */

  --footer-background: #cfd9e6;
  --footer-copyright: #efefef;

  --input-background: #1d5251a8;
  --input-border: #3fbfad;
  --input-text: #3fbfad;
  --input-border-focus: #ffffff;
  --input-text-focus: #ffffff;
  --input-text-focus-bg: #1d52515e;
  --input-label-focus: #82fffd;
  --input-text-highlighted: #8ff2e4;
  --input-autocomplete-bg: #1d5251;
  --input-autocomplete-text: #ffffff;
  --input-secondary-background: #efefef;
  --input-secondary-border: #707070;
  --input-secondary-text: #707070;
  --input-secondary-border-focus: #1d4f90;
  --input-secondary-text-focus: #1d4f90;
  --input-tertiary-border-focus: #1d4f90af;
  --input-tertiary-text-focus: #1d4f90af;

  --icon-background: #707070;
  --icon-text: #cfd9e6;
  --icon-focus: #1d4f90;

  --form-sign-background: #3fbfad61;
  --form-sign-border: #3fbfad66;

  --flag-background: #e0e0e0;
  --flag-shadow: #0000001f;

  --flip-background: transparent;
  --flip-front-background: transparent;
  --flip-front-text: #ffffff;
  --flip-back-background: #1d5251;
  --flip-back-text: #ffffff;

  --tag-color: #3fbfad;
  --tag-text-color: #222222;

  --text-default: #222222;
  --text-default-light: #707070;
  --text-primary: #1d4f90;

  --overlay-90: #000000de;
  --overlay-85: #00000085;
  --overlay-84: #000000ad;
  --overlay-65: #000000a6;
  --overlay-30: #00000061;
  --overlay-20: #00000033;
  --box-shadow: #00000029;

  --table-border: rgba(224, 224, 224, 1);

  --toast-error: #fae1e2;
  --toast-success: #f0f9fa;

  /* Dashboard */
  --dashboard-background: #efefef;
  --dashboard-primary-bg: #1d4f90;
  --dashboard-primary-bg-light: #3f51b5;
  --dashboard-secondary: #ef7622;
  --dashboard-menu-bg: #163d68;
  --dashboard-text-default: #222222;
  --dashboard-light-text: #f5f5f5;
  --dashboard-light-border: #f5f5f5;
  --dashboard-highlighted: #65cbc9;
  --dashboard-success: #3fbf7e;
  --dashboard-btn-success: #4caf50;
  --dashboard-btn-success-dark: #009a00;
  --dashboard-hr: #484848;
  --dashboard-top-toolbar: #222222;
  --dashboard-separator-title: #747474;
  --dashboard-border-light: #ffffff;
  --dashboard-box-shadow: #b1b1b1;
  --dashboard-icon-info: #4c5c71;
  --dashboard-separator-line: #c2c7e4;
  --dashboard-separator-tag: #c2c7e4;
  --dashboard-table-header: #ebebeb;
  --dashboard-danger: #ef2222;

  --card-background: #f6f6f6;
  --card-shadow: #00000029;
  --card-border: #1d4f90;
}
